import { toast } from "react-toastify";
import EditCreateForm from "../components/EditCreateForm";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";

import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import Spinner from "../components/Spinner";
import { addType, editPageAcronyms, fillItemRelatedObjects, getGpOrTccTypeByPageAcronym } from "../helpers";

const EditItemPage = () => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();

  const { user, refetchData, combData } = useOutletContext<any>();
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { type } = useParams();

  const [isEdit, setIsEdit] = useState(true);
  const [item, setItem] = useState<any | null>(null);
  const [errorObject, setErrorObject] = useState({});

  const toastSuccess = () => toast.success("Successfully updated!");
  const toastErrors = (errors: any) => {
    for (const key in errors) {
      if (errors[key] && errors[key].length > 0) {
        errors[key].forEach((message: string) => {
          toast.error(message);
        });
      }
    }
  };

  const updateItem = async (newItem: any, endpoint: string) => {
    try {
      const response = await axios({
        method: "patch",
        url: `${endpoint + newItem.id}/`,
        data: newItem,
      });
      if (response && response.status === 200) {
        setErrorObject({});
        refetchData();
        navigate("/");
        toastSuccess();
      }
    } catch (err: any) {
      if (err.response) {
        setItem(fillItemRelatedObjects(newItem, combData));
        setErrorObject(err.response.data);
        toastErrors(err.response.data);
      }
    }
  };

  useEffect(() => {
    if (
      !hasPermission("api.change_datacatalog") &&
      !hasPermission("api.change_packingmaterialhier")
    )
      return navigate("/no-access");
    const fetchItem = async () => {
      if (!type) return;

      try {
        const url = `${editPageAcronyms[type]}/${itemId}/`;
        const response = await axios({
          url,
          headers: {
            "Content-Type": "Access-Control-Allow-Origin",
            Accept: "application/json",
          },
        });
        const realGpOrTccType = getGpOrTccTypeByPageAcronym(type);
        setItem(addType(response.data, realGpOrTccType));
      } catch (err) {
        console.log(err);
      }
    };
    if (itemId) {
      setIsEdit(true);
      fetchItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, user, navigate]);

  return (
    <>
      {item ? (
        <EditCreateForm
          isEdit={isEdit}
          item={item}
          updateCreateItem={updateItem}
          errors={errorObject}
          setErrors={setErrorObject}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default EditItemPage;
