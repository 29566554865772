const CheckBox = ({ data }: any) => {
  return (
    <input
      className="checkbox"
      type="checkbox"
      value="Test"
      checked={data.active}
      readOnly
    />
  );
};

export default CheckBox;
