import Highlighter from "react-highlight-words";

const OptionFormatter = (
  option: { value: any; label: string },
  input: { inputValue: string }
) => {
  return (
    <Highlighter
      searchWords={[input.inputValue]}
      textToHighlight={option.label}
      highlightClassName="active-search-string"
    />
  );
};

export default OptionFormatter;
