const UserGuideAdmin = ({
  showPm,
  showDc
}: {
  showPm: boolean;
  showDc: boolean;
}) => {
  return (
    <div className="guide">
      <h1>User Guide</h1>
      <div className="table-of-content">
        {showDc ? (
          <>
            <h3>Data Catalog</h3>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-AuthenticationtoGlobalProductHierarchy">
                  Authentication to Global Product Hierarchy
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-Search">Search</a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-BasicUserspermissions">
                      Basic Users permissions
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-Edit">Edit</a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-Create">Create</a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-Category,Sub-CategoryandProductLineeditionorcreation">
                      Category, Sub-Category and Product Line edition or
                      creation
                    </a>
                    <ul className="toc-indentation">
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Adminrole)-Active/InactiveStatus">
                          Active/Inactive Status
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Adminrole)-Duplicatedcodes/values">
                          Duplicated codes / values
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Adminrole)-AssociateaSub-CategorytoaCategory">
                          Associate a Sub-Category to a Category
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Adminrole)-MandatoryFields">
                          Mandatory Fields
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-AdminLogs">
                  Admin Logs
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-Filtering">
                      Filtering
                    </a>
                  </li>
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-AdminLogsprocess">
                      Admin Logs process
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Adminrole)-ChangesintheMoldApp">
                  Changes in the MoldApp
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-PISApplication">
                      PIS Application
                    </a>
                    <ul className="toc-indentation">
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Adminrole)-ProductInformationscreen">
                          Product Information screen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Adminrole)-ProductMaster">
                      Product Master
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        ) : null}
        {showPm ? (
          <>
            <h3>Packing Materials</h3>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-GPH-PackingMaterials-AuthenticationtoGlobalProductHierarchy">
                  Authentication to Global Product Hierarchy
                </a>
              </li>
              <li>
                <a href="#UserManual-GPH-PackingMaterials-Search">Search</a>
              </li>
              <li>
                <a href="#UserManual-GPH-PackingMaterials-Edit">Edit</a>
              </li>
              <li>
                <a href="#UserManual-GPH-PackingMaterials-Create">Create</a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-GPH-PackingMaterials-PackingMaterialTypesandSub-Typeseditionorcreation">
                      Packing Material Types and Sub-Types edition or creation
                    </a>
                    <ul className="toc-indentation">
                      <li>
                        <a href="#UserManual-GPH-PackingMaterials-Active/InactiveStatus">
                          Active/Inactive Status
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-GPH-PackingMaterials-Duplicatedcodes/values">
                          Duplicated codes / values
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-GPH-PackingMaterials-AssociateaPackingMaterialSub-TypetoaPackingMaterialType">
                          Associate a Packing Material Sub-Type to a Packing
                          Material Type
                        </a>
                      </li>
                      <li>
                        <a href="#UserManual-GPH-PackingMaterials-MandatoryFields">
                          Mandatory Fields
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-GPH-PackingMaterials-AdminLogs">
                  Admin Logs
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-GPH-PackingMaterials-Filtering">
                      Filtering
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        ) : null}
      </div>
      {showDc ? (
        <div>
          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-AuthenticationtoGlobalProductHierarchy">
            Authentication to Global Product Hierarchy
          </h2>
          <p>
            Authentication should be done through the
            <strong> Mold Applications Main Menu</strong> &gt;
            <strong> Controls section.</strong> Clicking once on the
            <strong> Global Product Hierarchy</strong> hyperlink will start the
            authentication process. When this process ends, a new tab will open
            redirecting to the
            <strong> Global Product Hierarchy</strong> application.
          </p>
          <img
            className="image image-medium"
            alt="Global Product Hierarchy link"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956747.png"}
          />
          <span className="image-caption">Global Product Hierarchy link</span>

          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-Search">Search</h2>
          <p>
            In order to find Hierarchy Types, such as Category, Sub-Category and
            Product Line, select
            <strong> Global Product Hierarchy</strong> &gt;
            <strong> Search</strong> on the sidebar menu.
          </p>
          <img
            className="image"
            alt="Search sub-menu"
            src={process.env.PUBLIC_URL + "/userguide/admin/47022286.png"}
          />
          <span className="image-caption">Search sub-menu</span>

          <p>
            You can search for Category, Sub-Category and Product Line using
            multiple criteria by filling some or all of the fields
            <em> Type</em> and <em> Code/Value</em>, with values and clicking on
            the
            <strong> Search</strong> button on the right.
          </p>
          <ul>
            <li>
              <p>
                If selecting <strong>Category or Product Line </strong>as Type,
                only the first search line will appear and all existing values
                will be available in the auto-complete <em> Code/Value</em>{" "}
                field.
              </p>
            </li>
            <li>
              <p>
                If selecting <strong>Sub-Category </strong>as Type, the second
                line with Hierarchy Type will automatically appear with Category
                as Hierarchy Type. <em> Code/Value</em> field is auto-complete;
                if a Sub-Category value is selected, only corresponding
                Categories will be retrieved in <em> Hierarchy Code/Value</em>{" "}
                field; the same applies on the other way for Sub-Categories
                values if a Category value is already selected.
              </p>
            </li>
          </ul>
          <p>
            It's also possible to filter Hierarchy Types by filling the
            <em> search </em>input on the top-right of the table with criteria.
          </p>
          <img
            className="image"
            alt="Auto-complete search field
"
            src={process.env.PUBLIC_URL + "/userguide/admin/47251462.png"}
          />
          <span className="image-caption">Auto-complete search field</span>
          <img
            className="image"
            alt="Retrieve all sub-categories from a specific Category"
            src={process.env.PUBLIC_URL + "/userguide/admin/47251468.png"}
          />
          <span className="image-caption">
            Retrieve all sub-categories from a specific Category
          </span>
          <img
            className="image"
            alt="Search and sorting options"
            src={process.env.PUBLIC_URL + "/userguide/admin/47251474.png"}
          />
          <span className="image-caption">Search and sorting options</span>
          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-BasicUserspermissions">
            Basic Users permissions
          </h3>
          <p>
            For users with basic permissions, buttons Add and Edit are hidden
            and a recommendation message is displayed at the bottom of the page.
          </p>
          <img
            className="image"
            alt="Basic user view (add/edit buttons hidden, recommendation message)
"
            src={process.env.PUBLIC_URL + "/userguide/admin/47284244.png"}
          />
          <span className="image-caption">
            Basic user view (add/edit buttons hidden, recommendation message)
          </span>
          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-Edit">Edit</h2>
          <p>
            To start the editing process, click on the
            <strong> Edit </strong>button on the right side of the corresponding
            row, to be redirected to the Edit screen. Fields are prefilled with
            the current values and only <em> Code </em>and <em> Value</em> field
            are editable.
          </p>
          <img
            className="image"
            alt="Edit button"
            src={process.env.PUBLIC_URL + "/userguide/admin/46957130.png"}
          />
          <span className="image-caption">Edit button</span>

          <img
            className="image"
            alt="Value edition
"
            src={process.env.PUBLIC_URL + "/userguide/admin/47316995.png"}
          />
          <span className="image-caption">Value edition</span>

          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-Create">Create</h2>
          <p>
            To start the creating process, click on the
            <strong> Add button </strong>below the table, to be redirected to
            the Create screen, or go to the lateral bar menu and select
            <strong> Global Product Hierarchy</strong> &gt;
            <strong> Create new</strong>.
          </p>
          <img
            className="image"
            alt="Create sub-menu and Add button"
            src={process.env.PUBLIC_URL + "/userguide/admin/47120554.png"}
          />
          <span className="image-caption">Create sub-menu and Add button</span>

          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-Category,Sub-CategoryandProductLineeditionorcreation">
            Category, Sub-Category and Product Line edition or creation
          </h3>
          <h4 id="UserManual-TW-MDM-GPH(Adminrole)-Active/InactiveStatus">
            Active/Inactive Status
          </h4>
          <p>
            When editing a Category, Sub-Category or Product Line, this one can
            be (de)activated by ticking the
            <strong> Active </strong>checkbox. If deactivated, the value won’t
            be erased and will still remain in the database. When deactivating a
            Category, all Sub-Categories related to the former one will also be
            deactivated.
          </p>
          <p>
            When creating a Category, Sub-Category and Product Line, it can be
            defined as <strong>Active</strong> or <strong>Inactive</strong>, by
            ticking the Active checkbox. If Inactive, the value will still be
            created in the database.
          </p>
          <img
            className="image"
            alt="Active/Inactive checkbox"
            src={process.env.PUBLIC_URL + "/userguide/admin/47317023.png"}
          />
          <span className="image-caption">Active/Inactive checkbox</span>

          <h4 id="UserManual-TW-MDM-GPH(Adminrole)-Duplicatedcodes/values">
            Duplicated codes / values
          </h4>
          <p>
            It is not possible to edit or create a Category, Sub-Category or
            Product Line with an already existing <em> Code</em>.
          </p>
          <img
            className="image"
            alt="Error message when a code already exists"
            src={process.env.PUBLIC_URL + "/userguide/admin/46957136.png"}
          />
          <span className="image-caption">
            Error message when a code already exists
          </span>

          <p>
            If the <em> Value </em>already exists, the user will be warned about
            the duplication but can continue with the edition or creation of
            duplicates.
          </p>
          <img
            className="image"
            alt="Warning message for duplicated values"
            src={process.env.PUBLIC_URL + "/userguide/admin/47317001.png"}
          />
          <span className="image-caption">
            Warning message for duplicated values
          </span>

          <h4 id="UserManual-TW-MDM-GPH(Adminrole)-AssociateaSub-CategorytoaCategory">
            Associate a Sub-Category to a Category
          </h4>
          <p>
            A Sub-Category can only be associated to an already existing
            Category. The user has to first create a new Category before being
            able to associate the Sub-Category to this new Category. A tooltip
            displays this information.
          </p>
          <img
            className="image"
            alt="Informative tooltip about Category / Sub-Category creation."
            src={process.env.PUBLIC_URL + "/userguide/admin/46956813.png"}
          />
          <span className="image-caption">
            Informative tooltip about Category / Sub-Category creation.
          </span>

          <h4 id="UserManual-TW-MDM-GPH(Adminrole)-MandatoryFields">
            Mandatory Fields
          </h4>
          <p>All fields (Type, Code and Value) are mandatory.</p>
          <img
            className="image"
            alt="Mandatory fields warning "
            src={process.env.PUBLIC_URL + "/userguide/admin/46956819.png"}
          />
          <span className="image-caption">Mandatory fields warning</span>

          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-AdminLogs">Admin Logs</h2>
          <p>
            To access to the Admin Logs, select <strong>Admin Logs</strong> in
            the lateral bar menu.
          </p>
          <p>
            The access to <strong>Admin Logs </strong>is only available for a
            restricted group of users. For users with basic permissions, the
            menu is hidden.
          </p>
          <p>
            Changes (edition or creation) made to Global Product Hierarchy are
            recorded and displayed in the <strong>Admin Logs,</strong> where the
            following information is presented:
          </p>
          <ul>
            <li>
              <p>
                <strong>Action: </strong>edition or creation
              </p>
            </li>
            <li>
              <p>
                <strong>Field(s): </strong>what has been changed (hierarchy
                type, active/inactive status)
              </p>
            </li>
            <li>
              <p>
                <strong>Previous value(s):</strong> displayed in the case of an
                edition
              </p>
            </li>
            <li>
              <p>
                <strong>New value(s):</strong> what are the new values applied
              </p>
            </li>
            <li>
              <p>
                <strong>Date: </strong>when the action has been performed (date
                and hour)
              </p>
            </li>
            <li>
              <p>
                <strong>User: </strong>user name who performed the action
              </p>
            </li>
          </ul>
          <p>
            Admin Logs are pre-sorted based on the date value (descending sort).
          </p>
          <img
            className="image"
            alt="Admin Logs menu"
            src={process.env.PUBLIC_URL + "/userguide/admin/46957142.png"}
          />
          <span className="image-caption">Admin Logs menu</span>

          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-Filtering">Filtering</h3>
          <p>
            Admin Logs can be filtered by using the
            <strong> Year </strong>dropdown list or the
            <strong> Search </strong>field, or sorted by using the arrows in the
            table header. The number of entries displayed can also be changed.
          </p>
          <img
            className="image"
            alt="Filtering, searching and sorting options"
            src={process.env.PUBLIC_URL + "/userguide/admin/47284238.png"}
          />
          <span className="image-caption">
            Filtering, searching and sorting options
          </span>

          <p>
            <strong>Advanced filtering</strong> is also available and can be
            applied on all columns at the same time.
          </p>
          <img
            className="image"
            alt="Advanced filtering option and clean filter button"
            src={process.env.PUBLIC_URL + "/userguide/admin/47284232.png"}
          />
          <span className="image-caption">
            Advanced filtering option and clean filter button
          </span>

          <img
            className="image"
            alt="Advanced filtering widget"
            src={process.env.PUBLIC_URL + "/userguide/admin/47022294.png"}
          />
          <span className="image-caption">Advanced filtering widget</span>
          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-AdminLogsprocess">
            Admin Logs process
          </h3>
          <img
            className="image"
            alt="Admin Logs process"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956837.png"}
          />
          <span className="image-caption"></span>
          <h2 id="UserManual-TW-MDM-GPH(Adminrole)-ChangesintheMoldApp">
            Changes in the MoldApp
          </h2>
          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-PISApplication">
            PIS Application
          </h3>
          <p>
            To access to the Product Information screen, click on the
            <strong> PIS Application</strong> link and then
            <strong> Create New PIS</strong>.
          </p>
          <img
            className="image image-medium"
            alt="PIS Application link"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956699.png"}
          />
          <span className="image-caption">PIS Application link</span>

          <img
            className="image image-medium"
            alt="Create New PIS link"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956705.png"}
          />
          <span className="image-caption">Create New PIS link</span>

          <h4 id="UserManual-TW-MDM-GPH(Adminrole)-ProductInformationscreen">
            Product Information screen
          </h4>
          <p>
            “Category and Class” and “Brand Group and Name” fields were changed
            to
            <strong> Category</strong>, <strong>Sub-Category</strong> and
            <strong> Product Line</strong> mandatory fields, and
            <strong> Global Product Name</strong> field was added.
          </p>
          <img
            className="image image-medium"
            alt="New fields in Create new PIS"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956711.png"}
          />
          <span className="image-caption">New fields in Create new PIS</span>
          <img
            className="image image-medium"
            alt="Sub-Category can only be selected when a Category is choosen, and the Sub-Categories list depends on the Category"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956717.png"}
          />
          <span className="image-caption">
            Sub-Category can only be selected when a Category is choosen, and
            the Sub-Categories list depends on the Category
          </span>
          <img
            className="image image-medium"
            alt="Mandatory messages disappear when data is filled"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956723.png"}
          />
          <span className="image-caption">
            Mandatory messages disappear when data is filled
          </span>
          <h3 id="UserManual-TW-MDM-GPH(Adminrole)-ProductMaster">
            Product Master
          </h3>
          <p>
            To access to the <strong>Product Information</strong> screen, click
            on
            <strong> Product Master</strong> link and search for a product.
          </p>
          <img
            className="image image-medium"
            alt="Product Master link"
            src={process.env.PUBLIC_URL + "/userguide/admin/46956729.png"}
          />
          <span className="image-caption">Product Master link</span>
          <p>
            <strong>Category</strong>, <strong>Sub-Category</strong> and
            <strong> Product Line</strong> fields were added on the top of the
            page and old
            <strong> Category, Class, Brand Group and Brand Name </strong>
            values are displayed at the bottom as “<strong>(Old)</strong>”.
          </p>
          <img
            className="image image-medium"
            alt="Old and new values are displayed in the Product Information sheet"
            src={process.env.PUBLIC_URL + "/userguide/admin/47284323.png"}
          />
          <span className="image-caption">
            Old and new values are displayed in the Product Information sheet
          </span>
        </div>
      ) : null}

      {showPm ? (
        <div>
          <h1 className="mt-5">Packing Materials</h1>
          <h2 id="UserManual-GPH-PackingMaterials-AuthenticationtoGlobalProductHierarchy">
            Authentication to Global Product Hierarchy
          </h2>
          <p>
            Authentication should be done through the
            <strong> Mold Applications Main Menu</strong> &gt;
            <strong> Controls section.</strong> Clicking once on the
            <strong> Global Product Hierarchy</strong> hyperlink will start the
            authentication process. When this process ends, a new tab will open
            redirecting to the
            <strong> Global Product Hierarchy</strong> application.
          </p>

          <img
            className="image"
            alt="Global Product Hierarchy link"
            src={process.env.PUBLIC_URL + "/userguide/admin/58949638.png"}
          />
          <span className="image-caption">Global Product Hierarchy link</span>

          <h2 id="UserManual-GPH-PackingMaterials-Search">Search</h2>
          <p>
            In order to find
            <strong> Packing Material Types and Sub-Types</strong>, select
            <strong> Global Product Hierarchy</strong> &gt;
            <strong> Search</strong> on the sidebar menu.
          </p>

          <img
            className="image"
            alt=""
            src={process.env.PUBLIC_URL + "/userguide/admin/58982407.png"}
          />
          <span className="image-caption"></span>

          <p>
            You can search for
            <strong> Packing Material Type and/or Sub-Type</strong> using
            multiple criteria by filling some or all of the fields
            <em> Type</em> and <em> Code/Value</em> and clicking on the
            <strong> Search</strong> button on the right.
          </p>
          <p>
            It's also possible to filter Hierarchy Types by filling the
            <em> search </em>input on the top-right of the table with criteria.
          </p>

          <img
            className="image"
            alt="Auto-complete search field"
            src={process.env.PUBLIC_URL + "/userguide/admin/58687505.png"}
          />
          <span className="image-caption">Auto-complete search field</span>

          <img
            className="image"
            alt="Retrieve all Packing Material Sub-Types from a specific Packing Material Type"
            src={process.env.PUBLIC_URL + "/userguide/admin/58982413.png"}
          />
          <span className="image-caption">
            Retrieve all Packing Material Sub-Types from a specific Packing
            Material Type
          </span>

          <img
            className="image"
            alt="Search and sorting options"
            src={process.env.PUBLIC_URL + "/userguide/admin/59080711.png"}
          />
          <span className="image-caption">Search and sorting options</span>

          <h2 id="UserManual-GPH-PackingMaterials-Edit">Edit</h2>
          <p>
            To start the editing process, click on the
            <strong> Edit </strong>button on the right side of the corresponding
            row, to be redirected to the Edit screen. Fields are prefilled with
            the current values and only <em> Code </em>and
            <em> Value</em> field are editable.
          </p>

          <img
            className="image"
            alt="Edit button"
            src={process.env.PUBLIC_URL + "/userguide/admin/59015174.png"}
          />
          <span className="image-caption">Edit button</span>

          <p />

          <img
            className="image"
            alt="Value edition"
            src={process.env.PUBLIC_URL + "/userguide/admin/60194825.png"}
          />
          <span className="image-caption">Value edition</span>

          <h2 id="UserManual-GPH-PackingMaterials-Create">Create</h2>
          <p>
            To start the creating process, click on the
            <strong> Add button </strong>below the table, to be redirected to
            the Create screen, or go to the lateral bar menu and select
            <strong> Global Product Hierarchy</strong> &gt;
            <strong> Create</strong>.
          </p>

          <img
            className="image"
            alt="Create sub-menu and Add button"
            src={process.env.PUBLIC_URL + "/userguide/admin/58818577.png"}
          />
          <span className="image-caption">Create sub-menu and Add button</span>

          <h3 id="UserManual-GPH-PackingMaterials-PackingMaterialTypesandSub-Typeseditionorcreation">
            Packing Material Types and Sub-Types edition or creation
          </h3>
          <h4 id="UserManual-GPH-PackingMaterials-Active/InactiveStatus">
            Active/Inactive Status
          </h4>
          <p>
            When editing a Packing Material Type or Sub-Type, it can be
            (de)activated by ticking the <strong> Active </strong>checkbox. If
            deactivated, the value won't be erased and will still remain in the
            database. When deactivating a Type, all Sub-Types related to the
            former one will also be deactivated.
          </p>
          <p>
            When creating a Packing Material Type or Sub-Type, it can be defined
            as <strong> Active</strong> or <strong> Inactive</strong>, by
            ticking the Active checkbox. If Inactive, the value will still be
            created in the database.
          </p>

          <img
            className="image"
            alt="Active/Inactive checkbox"
            src={process.env.PUBLIC_URL + "/userguide/admin/60719105.png"}
          />
          <span className="image-caption">Active/Inactive checkbox</span>

          <h4 id="UserManual-GPH-PackingMaterials-Duplicatedcodes/values">
            Duplicated codes / values
          </h4>
          <p>
            It is not possible to edit or create a Packing Material Type or
            Sub-Type with an already existing <em> Code</em>.
          </p>

          <img
            className="image"
            alt="Error message when a code already exists"
            src={process.env.PUBLIC_URL + "/userguide/admin/60555280.png"}
          />
          <span className="image-caption">
            Error message when a code already exists
          </span>

          <p>
            If the <em> Value </em>already exists, the user will be warned about
            the duplication but can continue with the edition or creation of
            duplicates.
          </p>

          <img
            className="image"
            alt="Warning message for duplicated values"
            src={process.env.PUBLIC_URL + "/userguide/admin/58687556.png"}
          />
          <span className="image-caption">
            Warning message for duplicated values
          </span>

          <h4 id="UserManual-GPH-PackingMaterials-AssociateaPackingMaterialSub-TypetoaPackingMaterialType">
            Associate a Packing Material Sub-Type to a Packing Material Type
          </h4>
          <p>
            A Packing Material Sub-Type can only be associated to an already
            existing Packing Material Type. The user has to first create a new
            Type before being able to associate the Sub-Type to this new Type. A
            tooltip displays this information.
          </p>

          <img
            className="image"
            alt="Informative tooltip about Packing Material Type / Sub-Type creation."
            src={process.env.PUBLIC_URL + "/userguide/admin/59146267.png"}
          />
          <span className="image-caption">
            Informative tooltip about Packing Material Type / Sub-Type creation.
          </span>

          <h4 id="UserManual-GPH-PackingMaterials-MandatoryFields">
            Mandatory Fields
          </h4>
          <p>All fields (Type, Code and Value) are mandatory.</p>

          <img
            className="image"
            alt="Mandatory fields warning"
            src={process.env.PUBLIC_URL + "/userguide/admin/58818583.png"}
          />
          <span className="image-caption">Mandatory fields warning</span>

          <h2 id="UserManual-GPH-PackingMaterials-AdminLogs">Admin Logs</h2>
          <p>
            To access to the Admin Logs, select <strong> Admin Logs</strong> in
            the lateral bar menu.
          </p>
          <p>
            The access to <strong> Admin Logs </strong>is only available for a
            restricted group of users. For users with basic permissions, the
            menu is hidden.
          </p>
          <p>
            Changes (edition or creation) made to Global Product Hierarchy are
            recorded and displayed in the <strong> Admin Logs,</strong> where
            the following information is presented:
          </p>
          <ul>
            <li>
              <p>
                <strong> Action: </strong>edition or creation
              </p>
            </li>
            <li>
              <p>
                <strong> Field(s): </strong>what has been changed (hierarchy
                type, active/inactive status)
              </p>
            </li>
            <li>
              <p>
                <strong> Previous value(s):</strong> displayed in the case of an
                edition
              </p>
            </li>
            <li>
              <p>
                <strong> New value(s):</strong> what are the new values applied
              </p>
            </li>
            <li>
              <p>
                <strong> Date: </strong>when the action has been performed (date
                and hour)
              </p>
            </li>
            <li>
              <p>
                <strong> User: </strong>user name who performed the action
              </p>
            </li>
          </ul>
          <p>
            Admin Logs are pre-sorted based on the date value (descending sort).
          </p>

          <img
            className="image"
            alt="Admin Logs menu"
            src={process.env.PUBLIC_URL + "/userguide/admin/59080719.png"}
          />
          <span className="image-caption">Admin Logs menu</span>

          <h3 id="UserManual-GPH-PackingMaterials-Filtering">Filtering</h3>
          <p>
            Admin Logs can be filtered by using the
            <strong> Year </strong>dropdown list or the
            <strong> Search </strong>field, or sorted by using the arrows in the
            table header. The number of entries displayed can also be changed.
          </p>

          <img
            className="image"
            alt="Filtering, searching and sorting options"
            src={process.env.PUBLIC_URL + "/userguide/admin/58982423.png"}
          />
          <span className="image-caption">
            Filtering, searching and sorting options
          </span>

          <p>
            <strong> Advanced filtering</strong> is also available and can be
            applied on all columns at the same time.
          </p>

          <img
            className="image"
            alt="Advanced filtering option and clean filter button"
            src={process.env.PUBLIC_URL + "/userguide/admin/59146273.png"}
          />
          <span className="image-caption">
            Advanced filtering option and clean filter button
          </span>

          <img
            className="image"
            alt="Advanced filtering widget"
            src={process.env.PUBLIC_URL + "/userguide/admin/59179017.png"}
          />
          <span className="image-caption">Advanced filtering widget</span>
        </div>
      ) : null}
    </div>
  );
};

export default UserGuideAdmin;
