import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./components/navigation/Router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  process.env.NODE_ENV === "development" ? (
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  ) : (
    <Router />
  )
);
