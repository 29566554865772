import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IData, IGlobalProduct } from "../../../types/data.interface";
import {
  formatBooleanIntoOption,
  formatBooleanOptions,
  formatBrandIntoOption,
  formatBrandOptions,
  formatCapacityUnitIntoOption,
  formatCapacityUnitOptions,
  formatDatacalogIntoOption,
  formatDatacatalogOptions,
  getGlobalProductCode,
} from "../../../helpers";
import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import ReactModal from "react-modal";
import ConfirmValueModal from "../../ConfirmValueModal";
import { URL } from "../../../libraries/http/url";
import { toast } from "react-toastify";
import useAxios from "../../../utils/useAxios";
import { Predicates } from "../../../libraries/predicates/predicates";

export const GpnEditCreateForm = ({
  item,
  updateCreateItem,
  errors,
  isEdit,
}: {
  item: IGlobalProduct;
  updateCreateItem: any;
  errors: any;
  isEdit: boolean;
}) => {
  const axios = useAxios();
  const { dataCatalog, globalProducts, brands, locations, resPisTpsTypes } =
    useOutletContext<any>();

  const categoryOptions = dataCatalog.filter(
    (item: IData) => item.type === "Category"
  );
  const productLineOptions = dataCatalog.filter(
    (item: IData) => item.type === "Product Line"
  );
  const [subCategoryOptions, setSubCategoryOptions] = useState(
    dataCatalog.filter((item: IData) => item.type === "Sub-Category")
  );
  const [categorySelected, setCategorySelected] = useState(
    item.sub_category?.parent
  );
  const [subCategorySelected, setSubCategorySelected] = useState(
    item.sub_category
  );
  const [productLineSelected, setProductLineSelected] = useState(
    item.product_line
  );

  const [alwaysOnSelected, setAlwaysOnSelected] = useState(item.is_always_on);
  const [brandSelected, setBrandSelected] = useState(item.brand);
  const [capacityUnitSelected, setCapacityUnitSelected] = useState(
    item.capacity_uom
  );
  // const [sourcingTypeSelected, setSourcingTypeSelected] = useState(
  //   item.sourcing_type
  // );
  // const [vendorSelected, setVendorSelected] = useState(item.vendor);
  // const [tpsTypeSelected, setTpsTypeSelected] = useState(item.tps_type);

  const [newItem, setNewItem] = useState<IGlobalProduct>({
    ...item,
    capacity_value: item.capacity_value ? item.capacity_value : 0,
    suffix: item.suffix ? item.suffix : "",
  });

  const [showModal, setShowModal] = useState(false);
  const [existingItem, setExistingItem] = useState<IGlobalProduct | null>(null);
  const [nameWasChanged, setNameWasChanged] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const existing = globalProducts.filter(
      (item: IGlobalProduct) =>
        newItem.id !== item.id && newItem.name === item.name
    )[0];
    setExistingItem(existing);
    if (existing && (!isEdit || nameWasChanged)) {
      return setShowModal(true);
    } else {
      submit();
    }
  };

  const submit = async () => {
    const payload = {
      id: newItem.id,
      type: "Global Product",
      name: newItem.name,
      suffix: newItem.suffix,
      sub_category: newItem.sub_category ? newItem.sub_category.id : null,
      product_line: newItem.product_line ? newItem.product_line.id : null,
      is_always_on: newItem.is_always_on,
      brand: newItem.brand ? newItem.brand.id : null,
      capacity_value: newItem.capacity_value ? newItem.capacity_value : null,
      capacity_uom: newItem.capacity_uom ? newItem.capacity_uom : null,
      // sourcing_type: newItem.sourcing_type ? newItem.sourcing_type : null,
      // vendor: newItem.vendor ? newItem.vendor.location_id : null,
      // tps_type: newItem.tps_type?.res_pis_type_id,
    };
    await updateCreateItem(payload, "/global-products/");
  };

  const handleCategoryValue = (e: any) => {
    if (e) {
      setCategorySelected(e.value);
      setSubCategorySelected(null);
      setSubCategoryOptions(
        dataCatalog.filter((item: IData) => item.parent?.id == e.value.id)
      );
    } else {
      setCategorySelected(null);
      setSubCategorySelected(null);
      setSubCategoryOptions(
        dataCatalog.filter((item: IData) => item.type === "Sub-Category")
      );
    }
  };

  const handleSubCategoryValue = (e: any) => {
    if (e) {
      setSubCategorySelected(e.value);
      setCategorySelected(e.value.parent);
      setNewItem({ ...newItem, sub_category: e.value });
    } else {
      setSubCategorySelected(null);
      setNewItem({ ...newItem, sub_category: null });
    }
  };

  const handleProductLineValue = (e: any) => {
    if (e) {
      setProductLineSelected(e.value);
      setNewItem({ ...newItem, product_line: e.value });
    } else {
      setProductLineSelected(null);
      setNewItem({ ...newItem, product_line: null });
    }
  };

  const handleSuffixValue = (e: any) => {
    setNewItem({ ...newItem, suffix: e.target.value.replace(/\D+/g, "") });
  };

  const handleCapacityValue = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setNewItem({
        ...newItem,
        capacity_value: e.target.value,
      });
    }
  };

  const handleNameValue = (e: any) => {
    setNameWasChanged(true);
    setNewItem({ ...newItem, name: e.target.value });
  };

  const handleAlwaysOnValue = (e: any) => {
    if (e) {
      setAlwaysOnSelected(e.value);
      setNewItem({ ...newItem, is_always_on: e.value });
    } else {
      setAlwaysOnSelected(null);
      setNewItem({ ...newItem, is_always_on: null });
    }
  };

  const handleBrandValue = (e: any) => {
    if (e) {
      setBrandSelected(e.value);
      setNewItem({ ...newItem, brand: e.value });
    } else {
      setBrandSelected(null);
      setNewItem({ ...newItem, brand: null });
    }
  };

  const handleCapacityUnitValue = (e: any) => {
    if (e) {
      setCapacityUnitSelected(e.value);
      setNewItem({ ...newItem, capacity_uom: e.value });
    } else {
      setCapacityUnitSelected(null);
      setNewItem({ ...newItem, capacity_uom: null });
    }
  };

  // const handleSourcingTypeValue = (e: any) => {
  //   if (e) {
  //     setSourcingTypeSelected(e.value);
  //     setNewItem({
  //       ...newItem,
  //       sourcing_type: e.value,
  //       vendor: null,
  //       tps_type: null,
  //     });
  //   } else {
  //     setSourcingTypeSelected(null);
  //     setNewItem({
  //       ...newItem,
  //       sourcing_type: null,
  //       vendor: null,
  //       tps_type: null,
  //     });
  //   }
  //   setVendorSelected(null);
  //   setTpsTypeSelected(null);
  // };
  // const handleVendorValue = (e: any) => {
  //   if (e) {
  //     setVendorSelected(e.value);
  //     setNewItem({ ...newItem, vendor: e.value });
  //   } else {
  //     setVendorSelected(null);
  //     setNewItem({ ...newItem, vendor: null });
  //   }
  // };
  // const handleTpsTypeValue = (e: any) => {
  //   if (e) {
  //     setTpsTypeSelected(e.value);
  //     setNewItem({ ...newItem, tps_type: e.value });
  //   } else {
  //     setTpsTypeSelected(null);
  //     setNewItem({ ...newItem, tps_type: null });
  //   }
  // };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fillGpSuffixSuggestion = async () => {
      const searchParams = URL.createSearchParams({
        type: "G",
        sub_category_id: subCategorySelected?.id,
        product_line_id: productLineSelected?.id,
      });
      try {
        const response = await axios.get<string>(
          `/new-suffix/?${searchParams.toString()}`
        );
        const newSuffix: string = response.data;

        if (newSuffix === "-1") {
          toast.warning(
            "There might not exist any more available suffixes for this Category, Sub-Category and Product Line"
          );
          setNewItem({ ...newItem, suffix: "" });
        } else {
          setNewItem({ ...newItem, suffix: newSuffix });
        }
      } catch (err) {
        toast.error(
          "Something unexpected happened while retrieving the next suffix value. Proceed normally"
        );
        setNewItem({ ...newItem, suffix: "" });
      }
    };

    if (
      (Predicates.isNullOrUndefined(item.sub_category) &&
        Predicates.isNullOrUndefined(item.product_line) &&
        Predicates.isNotNullAndNotUndefined(subCategorySelected) &&
        Predicates.isNotNullAndNotUndefined(productLineSelected)) ||
      (Predicates.isNotNullAndNotUndefined(item.sub_category) &&
        Predicates.isNotNullAndNotUndefined(item.product_line) &&
        (subCategorySelected?.id !== item.sub_category.id ||
          productLineSelected?.id !== item.product_line.id))
    ) {
      fillGpSuffixSuggestion();
    } else if (
      Predicates.isNotNullAndNotUndefined(subCategorySelected) &&
      Predicates.isNotNullAndNotUndefined(productLineSelected) &&
      Predicates.isNotNullAndNotUndefined(item.sub_category) &&
      Predicates.isNotNullAndNotUndefined(item.product_line) &&
      subCategorySelected.id === item.sub_category.id &&
      productLineSelected.id === item.product_line.id
    ) {
      setNewItem({ ...newItem, suffix: item.suffix });
    }
  }, [subCategorySelected, productLineSelected]);

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ConfirmValueModal
          type={"Global Product"}
          valueThatExists={newItem.name}
          newItemDescription={`${getGlobalProductCode(newItem)} - ${
            newItem?.name
          }`}
          existingItemDescription={`${getGlobalProductCode(existingItem)} - ${
            existingItem?.name
          }`}
          handleCloseModal={closeModal}
          confirmSubmit={submit}
        />
      </ReactModal>
      <h6 className="mb-3 mt-3 border-bottom fw-bold fs-5">
        Global Product Definition
      </h6>
      <form onSubmit={handleSubmit}>
        <div className="row form-row">
          <div className="col-md-4 form-group">
            <label className="form-label">Category</label>
            <Select
              onChange={handleCategoryValue}
              value={
                categorySelected
                  ? formatDatacalogIntoOption(categorySelected)
                  : null
              }
              options={formatDatacatalogOptions(categoryOptions)}
              isSearchable
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              className={errors.parent ? "is-invalid" : ""}
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-4 form-group">
            <label className="form-label">Sub-Category</label>
            <Select
              onChange={handleSubCategoryValue}
              value={
                subCategorySelected
                  ? formatDatacalogIntoOption(subCategorySelected)
                  : null
              }
              options={formatDatacatalogOptions(subCategoryOptions)}
              isSearchable
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              className={errors.sub_category ? "is-invalid" : ""}
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-4 form-group">
            <label className="form-label">Product Line</label>
            <Select
              onChange={handleProductLineValue}
              value={
                productLineSelected
                  ? formatDatacalogIntoOption(productLineSelected)
                  : null
              }
              options={formatDatacatalogOptions(productLineOptions)}
              isSearchable
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              className={errors.product_line ? "is-invalid" : ""}
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">Global Product Code</label>
            <input
              value={`${categorySelected?.code ?? ""}${
                subCategorySelected?.code ?? ""
              }${productLineSelected?.code ?? ""}`}
              disabled={true}
              className={"form-control"}
              type="text"
            />
          </div>
          <div className="col-md-1 form-group">
            <label className="form-label">Suffix</label>
            <input
              value={newItem.suffix ? newItem.suffix : ""}
              onChange={handleSuffixValue}
              className={
                errors.suffix ? "is-invalid form-control" : "form-control"
              }
              type="text"
              maxLength={3}
            />
          </div>

          <div className="col-md-8 form-group">
            <label className="form-label">Global Product Name</label>
            <input
              value={newItem.name}
              onChange={handleNameValue}
              className={
                errors.name ? "is-invalid form-control" : "form-control"
              }
              type="text"
            />
          </div>
        </div>

        <h6 className="mb-3 mt-3 border-bottom fw-bold fs-5">
          Global Product Attributes
        </h6>

        <div className="row form-row">
          <div className="col-md-2 form-group">
            <label className="form-label">Always on</label>
            <Select
              onChange={handleAlwaysOnValue}
              formatOptionLabel={OptionFormatter}
              value={
                alwaysOnSelected != null
                  ? formatBooleanIntoOption(alwaysOnSelected)
                  : null
              }
              options={formatBooleanOptions()}
              classNamePrefix="react-select"
              className={errors.is_always_on ? "is-invalid" : ""}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md form-group">
            <label className="form-label">Brand</label>
            <Select
              onChange={handleBrandValue}
              formatOptionLabel={OptionFormatter}
              value={
                brandSelected ? formatBrandIntoOption(brandSelected) : null
              }
              options={formatBrandOptions(brands)}
              classNamePrefix="react-select"
              className={errors.brand ? "is-invalid" : ""}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md form-group">
            <label className="form-label">Product Capacity Value</label>
            <input
              value={newItem.capacity_value ? newItem.capacity_value : ""}
              onChange={handleCapacityValue}
              className={
                errors.capacity_value
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={10}
            />
          </div>

          <div className="col-md form-group">
            <label className="form-label">
              Product Capacity Unit of Measure
            </label>
            <Select
              onChange={handleCapacityUnitValue}
              formatOptionLabel={OptionFormatter}
              value={
                capacityUnitSelected
                  ? formatCapacityUnitIntoOption(capacityUnitSelected)
                  : null
              }
              options={formatCapacityUnitOptions()}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="col-md form-group mt-4">
            <input type="submit" className="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </>
  );
};

/* 
<div className="col-md-2 form-group">
            <label className="form-label">Sourcing Type</label>
            <Select
              onChange={handleSourcingTypeValue}
              formatOptionLabel={OptionFormatter}
              value={
                sourcingTypeSelected
                  ? formatSourcingTypeIntoOption(sourcingTypeSelected)
                  : null
              }
              options={formatSourcingTypeOptions()}
              classNamePrefix="react-select"
              className={errors.sourcing_type ? "is-invalid" : ""}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
          <div className="col-md-2 form-group">
            <label className="form-label">Vendor</label>
            <Select
              // TODO: Should be disabled unless Sourcing type = TPS
              onChange={handleVendorValue}
              formatOptionLabel={OptionFormatter}
              value={
                vendorSelected ? formatVendorIntoOption(vendorSelected) : null
              }
              options={formatVendorOptions(locations)}
              classNamePrefix="react-select"
              isDisabled={sourcingTypeSelected !== "TPS"}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
          <div className="col-md-2 form-group">
            <label className="form-label">TPS Type</label>
            <Select
              // TODO: Should be disabled unless Sourcing type = TPS
              onChange={handleTpsTypeValue}
              formatOptionLabel={OptionFormatter}
              value={
                tpsTypeSelected
                  ? formatTpsTypeIntoOption(tpsTypeSelected)
                  : null
              }
              options={formatTpsTypeOptions(resPisTpsTypes)}
              classNamePrefix="react-select"
              isDisabled={sourcingTypeSelected !== "TPS"}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
*/
