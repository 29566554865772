import axios from "axios";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Predicates } from "../libraries/predicates/predicates";

const baseURL = process.env.REACT_APP_API_BASE_URL;

let refreshTokenPromise = null;

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
      Accept: "application/json",
    },
  });

  const getRefreshToken = () =>
    axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens?.refresh,
    });

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getRefreshToken().then((res) => {
            refreshTokenPromise = null;
            return res;
          });
        }

        return refreshTokenPromise
          .then((res) => {
            localStorage.setItem("authTokens", JSON.stringify(res.data));
            setAuthTokens(res.data);
            setUser(jwt_decode(res.data.access));
            error.config.headers.Authorization = `Bearer ${res.data.access}`;
            return axiosInstance.request(error.config);
          })
          .catch(() => {
            const hasAuthTokens = Predicates.isNotNullAndNotUndefined(
              localStorage.getItem("authTokens")
            );

            if (hasAuthTokens) {
              logoutUser();
            }
          });
      }
      return Promise.reject(error);
    }
  );
  
  return axiosInstance;
};

export default useAxios;
