import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Outlet } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  IArtwork,
  IBrand,
  IChannel,
  IColor,
  IData,
  IFlavorScent,
  IGlobalProduct,
  ILicensedProperty,
  ILicensor,
  ILocation,
  IMarketingMaterial,
  IPmHier,
  IResPisTpsType,
  ITcc,
} from "./types/data.interface";
import Layout from "./components/navigation/Layout";
import useAxios from "./utils/useAxios";
import AuthContext from "./context/AuthContext";
import { addType } from "./helpers";

function App() {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [dataCatalog, setDataCatalog] = useState<IData[]>([]);
  const [globalProducts, setGlobalProducts] = useState<IGlobalProduct[]>([]);
  const [tccs, setTccs] = useState<ITcc[]>([]);
  const [pmhier, setPmhier] = useState<IPmHier[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [resPisTpsTypes, setResPisTpsTypes] = useState<IResPisTpsType[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [marketingMaterials, setMarketingMaterials] = useState<
    IMarketingMaterial[]
  >([]);
  const [flavorScents, setFlavorScents] = useState<IFlavorScent[]>([]);
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [licensors, setLicensors] = useState<ILicensor[]>([]);
  const [licensedProperties, setLicensedProperties] = useState<
    ILicensedProperty[]
  >([]);
  const [colors, setColors] = useState<IColor[]>([]);
  const [artworks, setArtworks] = useState<IArtwork[]>([]);

  const fetchDataCatalogAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/data-catalog/?ordering=code`);
      setDataCatalog(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchGlobalProductsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/global-products/?ordering=name`);
      setGlobalProducts(
        response.data.map((gp: IGlobalProduct) => addType(gp, "Global Product"))
      );
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchTccsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/tccs/?ordering=name`);
      setTccs(
        response.data.map((tcc: ITcc) =>
          addType(tcc, "Tupperware Consumer Code")
        )
      );
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchPmhierAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/packing-material-hier/?ordering=code`);
      setPmhier(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchLocationsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/locations/`);
      setLocations(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchResPisTpsTypesAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/tps-types/`);
      setResPisTpsTypes(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchBrandsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/brands/?ordering=name`);
      setBrands(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchMarketingMaterialsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/marketing-materials/?ordering=name`);
      setMarketingMaterials(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchFlavorScentsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/flavor-scents/?ordering=name`);
      setFlavorScents(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchChannelsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/channels/?ordering=name`);
      setChannels(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchLicensorsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/licensors/?ordering=name`);
      setLicensors(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchLicensedPropertiesAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/licensed-properties/?ordering=name`);
      setLicensedProperties(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchColorsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/colors/`);
      setColors(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchArtworksAsync = async () => {
    setIsLoading(true);
    try {
      // const response = await axios.get(`/product-master/designs/`);
      // setArtworks(response.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const refetchData = () => {
    if (hasPermission("api.view_datacatalog")) {
      fetchDataCatalogAsync();
      fetchGlobalProductsAsync();
      fetchTccsAsync();
      fetchLocationsAsync();
      fetchResPisTpsTypesAsync();
      fetchBrandsAsync();
      fetchMarketingMaterialsAsync();
      fetchFlavorScentsAsync();
      fetchChannelsAsync();
      fetchLicensorsAsync();
      fetchLicensedPropertiesAsync();
      fetchColorsAsync();
      fetchArtworksAsync();
    }
    if (hasPermission("api.view_packingmaterialhierarchy")) {
      fetchPmhierAsync();
    }
  };

  useEffect(() => {
    if (user) refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="App">
      <Layout>
        <Outlet
          context={{
            user,
            dataCatalog,
            setDataCatalog,
            pmhier,
            setPmhier,
            globalProducts,
            setGlobalProducts,
            tccs,
            setTccs,
            locations,
            setLocations,
            resPisTpsTypes,
            setResPisTpsTypes,
            brands,
            setBrands,
            marketingMaterials,
            setMarketingMaterials,
            flavorScents,
            setFlavorScents,
            channels,
            setChannels,
            licensors,
            setLicensors,
            licensedProperties,
            setLicensedProperties,
            colors,
            setColors,
            artworks,
            setArtworks,
            combData: [...dataCatalog, ...pmhier, ...globalProducts, ...tccs],
            isLoading,
            setIsLoading,
            refetchData,
          }}
        />
      </Layout>
    </div>
  );
}

export default App;
