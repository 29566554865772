const NotFound = () => {
  return (
    <div className="text-center container">
      <h3 className="mt-5">404</h3>
      <h3 className="my-3">Page Not Found</h3>
      <p>The address you entered does not exists.</p>
    </div>
  );
};

export default NotFound;
