import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../../App";
import { AuthProvider } from "./../../context/AuthContext";

// Pages imports
import GlobalProductHierarchyPage from "../../pages/GlobalProductHierarchyPage";
import AdminLogsPage from "../../pages/AdminLogsPage";
import CreateItemPage from "../../pages/CreateItemPage";
import EditItemPage from "../../pages/EditItemPage";
import NotFound from "../../pages/NotFound";
import NoAccessPage from "../../pages/NoAccessPage";
import UserGuidePage from "../../pages/UserGuidePage";

const Router = () => {
  return (
    <BrowserRouter basename={`${process.env.REACT_APP_BASE_NAME}`}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<GlobalProductHierarchyPage />} />
            <Route path="admin" element={<AdminLogsPage />} />
            <Route path="user-guide" element={<UserGuidePage />} />
            <Route path="create-new" element={<CreateItemPage />} />
            <Route path="edit/:type/:itemId" element={<EditItemPage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="no-access" element={<NoAccessPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
